import React, { useState, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import * as api from "../../api";
import Avatar from "../Avatar/Avatar";
import DataTable from "../DataTable/DataTable";
import Loader from "../Loader/Loader";
import OrderModal from "../OrderModal/OrderModal";
import RfidModal from "../RfidModal/RfidModal";
import getTimeString from "../../utils/getTimeString";

import "./CustomerModal.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCamera } from "@fortawesome/free-solid-svg-icons";
import CashOrCreditModal from "../CashOrCreditModal/CashOrCreditModal";
import { QrReader } from "react-qr-reader";

export default function CustomerModal({
  show = false,
  onShow = () => {},
  onHide = () => {},
  onExited = () => {},
  customer = null,
  cashTransactions = null,
  setCustomer = () => {},
  tickets = [],
  customerAccess = null,
  isStaff = null,
  isCheckedIn = null,
  party = null,
  customerLoadError = "",
  isActivated = null,
  showActivationButton = false,
  setIsActivated = () => {},
  setIsCheckedIn = () => {},
  showNfcButton = false,
  rfidSettings = {},
  showCameraQrReader = false,
  setShowCameraPopup = () => {},
}) {
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showRfidModal, setShowRfidModal] = useState(false);
  const [successCustomer, setSuccessCustomer] = useState(null);
  const [rfidErrorMessage, setRfidErrorMessage] = useState(null);
  const [detailOrder, setDetailOrder] = useState(null);
  const [error, setError] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [showMethodModal, setShowMethodModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [shouldCheckin, setShouldCheckin] = useState(false);

  useEffect(() => {
    setProfilePicture(customer?.profilePicture || "");
  }, [customer]);

  const onRfidSubmit = async (rfid) => {
    setRfidErrorMessage(null);

    try {
      const loadedCustomer = await api.customers.assignRfid(
        customer.pk,
        rfid,
        paymentMethod ? paymentMethod : null
      );
      setSuccessCustomer(loadedCustomer);
      setCustomer(loadedCustomer);
      setPaymentMethod("");
      if (shouldCheckin) {
        onCheckInSubmit();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.rfid) {
          setRfidErrorMessage(error.response.data.rfid[0]);
        } else {
          setRfidErrorMessage("Something Went Wrong");
        }
      } else if (error.response && error.response.status === 406) {
        setRfidErrorMessage("USER IS BLACKLISTED");
      } else {
        setRfidErrorMessage("Something Went Wrong");
      }
    }
  };

  const handleCustomerImageChange = (image) => {
    setProfilePicture(image);
  };

  const onCheckInSubmit = async () => {
    setError("");
    setShouldCheckin(false);
    try {
      const response = await api.tickets.checkIn(party.pk, customer.pk);
      if (response.status && response.status >= 200 && response.status < 300) {
        const { data } = response;
        let fetchedCustomer = data.customer;
        fetchedCustomer.tickets = data.tickets;
        setCustomer(fetchedCustomer);
        setIsCheckedIn(true);
      } else {
        setError("Something Went Wrong");
      }
    } catch (err) {
      if (
        err?.response?.data?.nonFieldErrors &&
        err?.response?.data?.nonFieldErrors
      ) {
        setError(err?.response?.data?.nonFieldErrors[0]);
      } else {
        setError("Something Went Wrong");
      }
    }
  };

  const onActivatePassClick = async (ticketPass) => {
    try {
      const response = await api.tickets.activatePass(ticketPass);
      if (response.status && response.status >= 200 && response.status < 300) {
        setIsActivated(true);
      } else {
        setError("Something Went Wrong");
      }
    } catch {
      setError("Something Went Wrong");
    }
  };

  const handleOrderModalExit = () => {
    setDetailOrder(null);
  };

  const handleRfidModalExit = () => {
    setRfidErrorMessage(null);
    setSuccessCustomer(null);
  };

  const handleAssignRfidClick = (hasRfid) => {
    if (
      (!hasRfid && rfidSettings?.chargeForRfidAssignment) ||
      (hasRfid && rfidSettings?.chargeForRfidReplacement)
    ) {
      setShowMethodModal(true);
    } else {
      setShowRfidModal(true);
    }
  };

  const handleMethodChoice = (choice) => {
    setPaymentMethod(choice);
    setShowMethodModal(false);
    setShowRfidModal(true);
  };

  return (
    <Modal
      className="CustomerModal"
      centered
      size="xl"
      show={show}
      onHide={() => {
        setError("");
        onHide();
      }}
      onExited={onExited}
      onShow={onShow}
    >
      <Modal.Body>
        {customerLoadError && (
          <h1 className="text-danger">{customerLoadError}</h1>
        )}
        {!customer && !customerLoadError && <Loader />}
        {customer && (
          <>
            <Row>
              <Col xs={12} className="customerModal-header">
                <h1 className="text-center">
                  {customer.firstName} {customer.lastName}
                </h1>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="close-icon"
                  size="2x"
                  onClick={() => {
                    onExited();
                    onHide();
                  }}
                />
              </Col>

              <Col className="d-lg-none" xs={12}>
                <Avatar
                  src={profilePicture}
                  width={125}
                  editable={true}
                  editCallback={handleCustomerImageChange}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  customer={customer?.pk}
                />
              </Col>

              <Col className="my-4" style={{ fontSize: 22 }} xs={12} lg={6}>
                <Row>
                  {customerAccess !== null && (
                    <React.Fragment>
                      <Col className="mt-2" xs={12}>
                        {customerAccess ? (
                          <div className="access-check">
                            <h4 className="text-success mb-0 access-check-text">
                              Allowed
                            </h4>
                            <div className="access-check-color access-check-color--success"></div>
                          </div>
                        ) : (
                          <div className="access-check">
                            <h4 className="text-danger mb-0 access-check-text">
                              Denied
                            </h4>
                            <div className="access-check-color access-check-color--danger"></div>
                          </div>
                        )}
                        {isActivated === true && !isStaff && (
                          <div className="access-check">
                            <h4 className="text-success mb-0 access-check-text">
                              Activated
                            </h4>
                            <div className="access-check-color access-check-color--success"></div>
                          </div>
                        )}
                        {isActivated === false && !isStaff && (
                          <div className="access-check">
                            <h4 className="text-danger mb-0 access-check-text">
                              Not Activated
                            </h4>
                            <div className="access-check-color access-check-color--danger"></div>
                          </div>
                        )}
                        <div className="access-check">
                          {isCheckedIn ? (
                            <div className="access-check">
                              <h4 className="text-success mb-0 access-check-text">
                                Checked In
                              </h4>
                              <div className="access-check-color access-check-color--success"></div>
                            </div>
                          ) : (
                            <div className="access-check">
                              <h4 className="mb-0 access-check-text">
                                Not Checked In
                              </h4>
                              <div className="access-check-color access-check-color--neutral"></div>
                            </div>
                          )}
                        </div>
                      </Col>
                      {isStaff && (
                        <Col xs={6}>
                          <div className="staff-flag">STAFF</div>
                        </Col>
                      )}
                    </React.Fragment>
                  )}
                  <Col className="mt-0" xs={12}>
                    Email: {customer.email}
                  </Col>
                  <Col className="mt-2" xs={12}>
                    Mobile number: {customer.mobileNumber}
                  </Col>
                  {customer.balance !== undefined && (
                    <Col className="mt-2" xs={12}>
                      Balance: {customer.balance / 100} EGP
                    </Col>
                  )}
                  {tickets.map((ticket, index) => (
                    <React.Fragment key={index}>
                      <h4 className="mt-2 pl-3 mb-0">
                        Ticket{" "}
                        {ticket?.reservation?.event?.name
                          ? "(" + ticket?.reservation?.event?.name + ")"
                          : ""}
                      </h4>
                      {ticket.tier && (
                        <Col className="mt-2 pl-5" xs={12}>
                          Ticket Type: {ticket.tier.name}{" "}
                          {ticket.tier.nameLine2} {ticket.tier.nameLine3}
                        </Col>
                      )}
                      {ticket.reservation && (
                        <React.Fragment>
                          <Col className="mt-2 pl-5" xs={12}>
                            Reservation Code: {ticket.reservation.code}
                          </Col>
                          <Col className="mt-2 pl-5" xs={12}>
                            Reservation Head:{" "}
                            {ticket.reservation.reservationHead.firstName}{" "}
                            {ticket.reservation.reservationHead.lastName}
                          </Col>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ))}
                  {showActivationButton &&
                    isActivated === false &&
                    isStaff !== true && (
                      <Col className="mt-2">
                        <Button
                          variant={"success"}
                          onClick={() =>
                            onActivatePassClick(customer.ticketPass)
                          }
                        >
                          Activate Pass
                        </Button>
                      </Col>
                    )}
                  <Col
                    className={
                      "mt-2 " +
                      (customer.hasRfid ? "text-success" : "text-danger")
                    }
                    xs={12}
                  >
                    {customer.hasRfid
                      ? "Has assigned RFID"
                      : "Does not have an RFID"}
                  </Col>
                  {party?.flow == "check-in-and-assignment" && (
                    <React.Fragment>
                      {isCheckedIn ? (
                        <Button
                          variant={"info"}
                          onClick={() =>
                            handleAssignRfidClick(customer?.hasRfid)
                          }
                        >
                          Replace RFID
                        </Button>
                      ) : (
                        <Button
                          variant={"success"}
                          onClick={() => {
                            setShouldCheckin(true);
                            handleAssignRfidClick(customer?.hasRfid);
                          }}
                        >
                          Check-In & Assign RFID
                        </Button>
                      )}
                    </React.Fragment>
                  )}

                  {party?.flow == "check-in" && (
                    <React.Fragment>
                      <Button
                        variant={customer.hasRfid ? "info" : "success"}
                        onClick={() => handleAssignRfidClick(customer?.hasRfid)}
                      >
                        {customer.hasRfid ? "Replace RFID" : "Assign RFID"}
                      </Button>
                      {!isCheckedIn && (
                        <Button
                          variant="success"
                          onClick={() => onCheckInSubmit()}
                          className="ml-2"
                        >
                          Check In
                        </Button>
                      )}
                    </React.Fragment>
                  )}
                  {showCameraQrReader && (
                    <FontAwesomeIcon
                      icon={faCamera}
                      className="qr-code-icon ml-2"
                      size="2x"
                      onClick={() => {
                        setShowCameraPopup(true);
                        onExited();
                        onHide();
                      }}
                    />
                  )}
                </Row>
                <p className="text-danger">{error}</p>
              </Col>

              <Col className="d-none d-lg-block" xs={12} lg={6}>
                <Avatar
                  src={profilePicture}
                  width={250}
                  editable={true}
                  editCallback={handleCustomerImageChange}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  customer={customer?.pk}
                />
              </Col>
            </Row>

            {cashTransactions && (
              <Row>
                <Col xs={12}>
                  <h2 className="text-center">Transactions</h2>

                  <DataTable
                    config={{
                      keyField: "pk",
                      mapper: [
                        {
                          title: "#TID",
                          dataKey: "pk",
                        },
                        {
                          title: "Type",
                          dataKey: "type",
                        },
                        {
                          title: "Processed By",
                          render: (cashTransaction) => {
                            const creator = cashTransaction.creator;
                            return (
                              `${creator.firstName} ${creator.lastName}`.trim() ||
                              creator.email
                            );
                          },
                        },
                        {
                          title: "Amount",
                          render: (cashTransaction) =>
                            `${cashTransaction.amount / 100} ${
                              cashTransaction.currency
                            }`,
                        },
                        {
                          title: "Time",
                          render: (cashTransaction) =>
                            getTimeString(cashTransaction.createdAt),
                        },
                        {
                          title: "Order ID",
                          render: (cashTransaction) =>
                            cashTransaction.order && (
                              <span>
                                {cashTransaction.order.pk}
                                &nbsp;
                                {cashTransaction.order.refundedAt && (
                                  <span className="text-danger">
                                    (Refunded)
                                  </span>
                                )}
                              </span>
                            ),
                          onClick: (cashTransaction) => {
                            if (!cashTransaction.order) return;

                            setDetailOrder(cashTransaction.order);
                            setShowOrderModal(true);
                          },
                          clickable: (cashTransaction) =>
                            !!cashTransaction.order,
                        },
                        {
                          title: "Outlet",
                          render: (cashTransaction) =>
                            cashTransaction?.outlet?.name ?? "",
                        },
                      ],
                    }}
                    data={cashTransactions}
                  />
                </Col>
              </Row>
            )}
          </>
        )}

        <OrderModal
          show={showOrderModal}
          onHide={() => setShowOrderModal(false)}
          onExited={handleOrderModalExit}
          order={detailOrder}
          customer={customer}
          showNfcButton={showNfcButton}
        />

        <RfidModal
          show={showRfidModal}
          onHide={() => setShowRfidModal(false)}
          onExited={handleRfidModalExit}
          onSubmit={onRfidSubmit}
          successMessage={"RFID assigned successfully!"}
          successCustomer={successCustomer}
          errorMessage={rfidErrorMessage}
          hideBalance
          title="Scan New RFID"
          showNfcButton={showNfcButton}
        />
        <CashOrCreditModal
          show={showMethodModal}
          showFocButton={true}
          onHide={() => setShowMethodModal(false)}
          handleChoice={handleMethodChoice}
          price={rfidSettings?.price}
        />
      </Modal.Body>
    </Modal>
  );
}
