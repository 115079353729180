import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import * as api from "../../api";
import CustomerModal from "../../components/CustomerModal/CustomerModal";
import SearchField from "../../components/fields/SearchField/SearchField";
import UserForm from "../../components/forms/UserForm/UserForm";

import "./AccessControl.sass";
import DataTable from "../../components/DataTable/DataTable";
import GateAccessTab from "../../components/Tabs/gateAccessTab/gateAccessTab";
import PassActivation from "../../components/Tabs/passActivation/passActivation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { QrReader } from "react-qr-reader";

export default function AccessControl({
  currentUser,
  party,
  event,
  rfidSettings,
}) {
  const [customers, setCustomers] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [customerAccess, setCustomerAccess] = useState(null);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customerCount, setCustomerCount] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [tab, setTab] = useState("checkins");
  const [isStaff, setIsStaff] = useState(null);
  const [isCheckedIn, setIsCheckedIn] = useState(null);
  const [error, setError] = useState("");
  const [focusQrFlag, setFocusQrFlag] = useState(true);
  const [focusSearchFlag, setFocusSearchFlag] = useState(false);
  const [isActivated, setIsActivated] = useState(null);
  const [showCameraPopup, setShowCameraPopup] = useState(false);

  const generateResponseHandler = (reset) => (response) => {
    setCustomerCount(response.count);
    let newCustomers;
    if (reset) {
      newCustomers = response.results;
    } else {
      newCustomers = [...customers, ...response.results];
    }
    setCustomers(newCustomers);
    setNextPage(response.next);
  };

  let requestAlreadySent = false;
  const onBottomReached = () => {
    if (requestAlreadySent || !nextPage) return;

    requestAlreadySent = true;
    api.genericGet(nextPage).then(generateResponseHandler(false));
  };

  const onQrCodeChange = useDebouncedCallback(async (qrCode) => {
    setShowCustomerModal(true);
    try {
      let data = await api.entryAccess.entryAccess(qrCode, party?.pk);
      const loadedCustomer = data.customer;
      loadedCustomer.tickets = data.tickets;
      loadedCustomer.ticketPass = data.ticketPass;
      let user = data.user;
      let staff = false;
      if (
        user.isAccessControl ||
        user.isOutletManager ||
        user.isTeller ||
        user.isVendor ||
        user.isStaff ||
        user.isNcStaff
      ) {
        staff = true;
      }
      setIsStaff(staff);
      setIsCheckedIn(data.isCheckedIn);
      setIsActivated(data.isActivated);
      setCustomerAccess(staff ? true : data.hasAccess);
      setCustomer(loadedCustomer);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        setError(err.response.data.detail);
      } else {
        setError("Something went wrong");
      }
    }
  }, 100);

  const onQueryChange = useDebouncedCallback((query) => {
    setError("");
    if (query === "") query = null;
    api.customers.list(query, true).then(generateResponseHandler(true));
  }, 100);

  const onQrQueryChange = (query) => {
    setError("");
    if (!query) {
      setCustomer(null);
      return;
    }

    onQrCodeChange(query);
  };

  const handleCustomerClick = async (customer) => {
    setShowCustomerModal(true);
    try {
      let data = await api.entryAccess.entryAccessByPK(customer.pk, party.pk);
      const loadedCustomer = data.customer;
      loadedCustomer.tickets = data.tickets;
      loadedCustomer.ticketPass = data.ticketPass;
      let user = data.user;
      let staff = false;
      if (
        user.isAccessControl ||
        user.isOutletManager ||
        user.isTeller ||
        user.isVendor ||
        user.isStaff ||
        user.isNcStaff
      ) {
        staff = true;
      }
      setIsStaff(staff);
      setIsCheckedIn(data.isCheckedIn);
      setCustomerAccess(staff ? true : data.hasAccess);
      setIsActivated(data.isActivated);
      setCustomer(loadedCustomer);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        setError(err.response.data.detail);
      } else {
        setError("Something went wrong");
      }
    }
  };

  useEffect(() => {
    if (!customers && (tab == "passActivation" || tab == "customers")) {
      api.customers.list(null ,true).then(generateResponseHandler(true));
    }
  }, [customers, tab]);

  useEffect(() => {
    if (!tab) return;
    else if (tab === "checkins" || tab === "passActivation") {
      setFocusQrFlag(true);
    } else if (tab === "customers") {
      setFocusSearchFlag(true);
    }
  }, [tab]);

  return (
    <Row className="AccessControl">
      <Col className="mt-4" xs={12}>
        <Card className="h-100">
          <Card.Body>
            <Row>
              <Col className="mx-auto" md={12} lg={11}>
                <Row>
                  <Col>
                    <h1 className="text-center">Access Control</h1>
                  </Col>
                </Row>

                <Tab.Container defaultActiveKey="checkins">
                  <Row>
                    <Col className="mx-auto tabs" xs={12}>
                      <Nav justify variant="pill">
                        {currentUser?.hasCheckInPermission && (
                          <Nav.Item onClick={() => setTab("checkins")}>
                            <Nav.Link eventKey="checkins">Check in</Nav.Link>
                          </Nav.Item>
                        )}
                        {currentUser?.hasCustomerViewPermission && (
                          <Nav.Item onClick={() => setTab("customers")}>
                            <Nav.Link eventKey="customers">Customers</Nav.Link>
                          </Nav.Item>
                        )}
                        {currentUser?.hasCustomerCreatePermission && (
                          <Nav.Item onClick={() => setTab("newCustomer")}>
                            <Nav.Link eventKey="newCustomer">
                              New Customer
                            </Nav.Link>
                          </Nav.Item>
                        )}
                        {currentUser?.hasGateAccessPermission && (
                          <Nav.Item onClick={() => setTab("gateAccess")}>
                            <Nav.Link eventKey="gateAccess">
                              Gate access
                            </Nav.Link>
                          </Nav.Item>
                        )}
                        {currentUser?.hasActivationPermission && (
                          <Nav.Item onClick={() => setTab("passActivation")}>
                            <Nav.Link eventKey="passActivation">
                              Pass Activation
                            </Nav.Link>
                          </Nav.Item>
                        )}
                      </Nav>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <h1>{party?.name}</h1>
                  </Row>

                  <Row className="mt-4">
                    <Col xs={12}>
                      <Tab.Content>
                        {currentUser?.hasCheckInPermission &&
                          tab === "checkins" && (
                            <Tab.Pane eventKey="checkins">
                              <Row>
                                <Col xs={12}>
                                  <SearchField
                                    label="QR Code"
                                    type="text"
                                    onQueryChange={onQrQueryChange}
                                    focusFlag={focusQrFlag}
                                    setFocusFlag={setFocusQrFlag}
                                    showCameraQrReader={true}
                                    isAlwaysFocused={true}
                                    setShowCameraPopup={setShowCameraPopup}
                                  />
                                </Col>
                              </Row>
                            </Tab.Pane>
                          )}
                        {currentUser?.hasCustomerViewPermission && (
                          <Tab.Pane eventKey="customers">
                            <Row className="mt-5">
                              <Col xs={12}>
                                <SearchField
                                  label="Search"
                                  onQueryChange={onQueryChange}
                                  focusFlag={focusSearchFlag}
                                  setFocusFlag={setFocusSearchFlag}
                                  showNfcButton={true}
                                />
                              </Col>
                            </Row>

                            <Row className="mt-4 table-container">
                              <Col className="h-100 mx-auto" xs={12}>
                                <DataTable
                                  config={{
                                    keyField: "pk",
                                    mapper: [
                                      {
                                        title: "Name",
                                        render: (customer) =>
                                          `${customer.firstName} ${customer.lastName}`,
                                        onClick: handleCustomerClick,
                                      },
                                      {
                                        title: "Email",
                                        dataKey: "email",
                                      },
                                      {
                                        title: "Mobile Number",
                                        dataKey: "mobileNumber",
                                      },
                                    ],
                                  }}
                                  data={customers}
                                  dataCount={customerCount}
                                  onBottomReached={onBottomReached}
                                  showCount
                                  dataType={"customers"}
                                />
                              </Col>
                            </Row>
                          </Tab.Pane>
                        )}
                        {currentUser?.hasCustomerCreatePermission && (
                          <Tab.Pane eventKey="newCustomer">
                            <UserForm party={party} event={event} />
                          </Tab.Pane>
                        )}
                        {currentUser?.hasGateAccessPermission && (
                          <Tab.Pane eventKey="gateAccess">
                            {tab === "gateAccess" && (
                              <GateAccessTab party={party?.pk} />
                            )}
                          </Tab.Pane>
                        )}
                        {currentUser?.hasActivationPermission && (
                          <Tab.Pane eventKey="passActivation">
                            {tab === "passActivation" && (
                              <PassActivation
                                onQrQueryChange={onQrQueryChange}
                                onQueryChange={onQueryChange}
                                focusQrFlag={focusQrFlag}
                                setFocusQrFlag={setFocusQrFlag}
                                focusSearchFlag={focusSearchFlag}
                                setFocusSearchFlag={setFocusSearchFlag}
                                customers={customers}
                                customerCount={customerCount}
                                onBottomReached={onBottomReached}
                                handleCustomerClick={handleCustomerClick}
                                setShowCameraPopup={setShowCameraPopup}
                              />
                            )}
                          </Tab.Pane>
                        )}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <CustomerModal
        show={showCustomerModal}
        onShow={() => setFocusQrFlag(false)}
        onExited={() => {
          setFocusQrFlag(true);
          setFocusSearchFlag(true);
          setError("");
          setCustomer(null);
        }}
        onHide={() => setShowCustomerModal(false)}
        customer={customer}
        tickets={customer?.tickets}
        setCustomer={setCustomer}
        customerAccess={customerAccess}
        isStaff={isStaff}
        isCheckedIn={isCheckedIn}
        setIsCheckedIn={setIsCheckedIn}
        isActivated={isActivated}
        setIsActivated={setIsActivated}
        party={party}
        customerLoadError={error}
        showActivationButton={tab === "passActivation"}
        showNfcButton={true}
        rfidSettings={rfidSettings}
        showCameraQrReader={tab === "checkins"}
        setShowCameraPopup={setShowCameraPopup}
      />
      {showCameraPopup && (
        <div className="camera-popup">
          <FontAwesomeIcon
            icon={faTimes}
            className="qr-code-close-icon"
            size="2x"
            onClick={() => setShowCameraPopup(false)}
          />
          <QrReader
            onResult={(result, error) => {
              if (!!result) {
                setShowCameraPopup(false);
                onQrQueryChange(result?.text);
              }
            }}
            constraints={{ facingMode: "environment" }}
            containerStyle={{ width: "100%", height: "100%", zIndex: "1" }}
            videoStyle={{
              width: "100%",
              height: "100%",
              zIndex: "1",
              position: "fixed",
            }}
          />
        </div>
      )}
    </Row>
  );
}
