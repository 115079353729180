import React from "react";
import { Col, Row } from "react-bootstrap";
import DataTable from "../../DataTable/DataTable";
import SearchField from "../../fields/SearchField/SearchField";

const PassActivation = ({
  onQrQueryChange = () => {},
  onQueryChange = () => {},
  focusQrFlag,
  setFocusQrFlag = () => {},
  focusSearchFlag,
  setFocusSearchFlag = () => {},
  customers,
  customerCount,
  onBottomReached = () => {},
  handleCustomerClick = () => {},
  setShowCameraPopup = () => {}
}) => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <SearchField
            label="QR Code"
            type="text"
            onQueryChange={onQrQueryChange}
            focusFlag={focusQrFlag}
            setFocusFlag={setFocusQrFlag}
            showCameraQrReader={true}
            setShowCameraPopup={setShowCameraPopup}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12}>
          <SearchField
            label="Search"
            onQueryChange={onQueryChange}
            focusFlag={focusSearchFlag}
            setFocusFlag={setFocusSearchFlag}
            showNfcButton={true}
          />
        </Col>
      </Row>

      <Row className="mt-4 table-container">
        <Col className="h-100 mx-auto" xs={12}>
          <DataTable
            config={{
              keyField: "pk",
              mapper: [
                {
                  title: "Name",
                  render: (customer) =>
                    `${customer.firstName} ${customer.lastName}`,
                  onClick: handleCustomerClick,
                },
                {
                  title: "Email",
                  dataKey: "email",
                },
                {
                  title: "Mobile Number",
                  dataKey: "mobileNumber",
                },
              ],
            }}
            data={customers}
            dataCount={customerCount}
            onBottomReached={onBottomReached}
            showCount
            dataType={"customers"}
          />
        </Col>
      </Row>
    </>
  );
};

export default PassActivation;
