import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import "./SearchField.sass";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import NfcButton from "../../NfcButton/NfcButton";

export default function SearchField({
  onQueryChange = () => {},
  label = "Search",
  type = "text",
  focusFlag = false,
  setFocusFlag = () => {},
  isAlwaysFocused = false,
  showCameraQrReader = false,
  showNfcButton = false,
  setShowCameraPopup = () => {}
}) {
  const [query, setQuery] = useState("");
  const [nfcReadingEnabled, setNfcReadingEnabled] = useState(false);
  const field = useRef(null);

  const updateQuery = (query, isClear = false, isQrCamera = false) => {
    if (document.activeElement == field.current || isClear || isQrCamera) {
      setQuery(query);
      onQueryChange(query);
    }
  };

  useEffect(() => {
    if (focusFlag) {
      if (field.current) {
        field.current.focus();
        if (!isAlwaysFocused) {
          setFocusFlag(false);
        }
        setQuery("");
        onQueryChange("");
      }
    }
  }, [focusFlag, isAlwaysFocused]);

  useEffect(() => {
    if (nfcReadingEnabled) {
      field?.current?.focus();
    }
  }, [nfcReadingEnabled]);

  return (
    <div className="search-field">
      <Row className="mb-2">
        <Col xs={12}>
          <label htmlFor="query">{label}</label>

          <Button
            className="float-right"
            onClick={() => {
              updateQuery("", true, false);
              if (field.current) {
                field.current.focus();
              }
            }}
          >
            Clear
          </Button>
        </Col>
      </Row>

      <div className="search-field-container">
        <input
          className="form-control"
          type={type}
          name="query"
          id="query"
          value={query}
          onChange={(event) => updateQuery(event.target.value)}
          onBlur={() => {
            if (isAlwaysFocused && focusFlag) {
              field?.current?.focus();
            }
          }}
          ref={field}
        />
        {showCameraQrReader && (
          <FontAwesomeIcon
            icon={faCamera}
            className="qr-code-icon"
            size="2x"
            onClick={() => setShowCameraPopup(true)}
          />
        )}
        {/* showNfcButton && (
          <NfcButton
            nfcReadingEnabled={nfcReadingEnabled}
            setNfcReadingEnabled={setNfcReadingEnabled}
            handleChange={(value) => updateQuery(value, false, false)}
          />
        ) */}
      </div>
  
    </div>
  );
}
